(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/chat/assets/javascripts/chat-wrapper-container.js') >= 0) return;  svs.modules.push('/components/marketplace/chat/assets/javascripts/chat-wrapper-container.js');
'use strict';

const {
  chat
} = svs.components.marketplace;
setGlobal('svs.components.marketplace.chat.ChatWrapperContainer', ReactRedux.connect(_ref => {
  let {
    customerId
  } = _ref;
  return {
    customerId
  };
})(chat.ChatWrapper));

 })(window);