(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/chat/assets/javascripts/chat-wrapper.js') >= 0) return;  svs.modules.push('/components/marketplace/chat/assets/javascripts/chat-wrapper.js');
'use strict';

const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  chatUi
} = svs.components.chat;
const {
  lbUtils
} = svs.components;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const offset = 77;
const topContentWrapperSelector = svs.core.template.getConfig().hasBalanceBar ? '.js-top-content-wrapper' : '.fixed-top-content';
function getY() {
  const marketplaceRoutedTabs = document.querySelector('.marketplace-routed-tabs');
  const contentWrapper = document.querySelector(topContentWrapperSelector);
  return (contentWrapper ? contentWrapper.getBoundingClientRect().height : 0) + (marketplaceRoutedTabs ? marketplaceRoutedTabs.getBoundingClientRect().height : 0) + offset;
}
const defaultErrorFallback = () => null;
const ChatWrapper = _ref => {
  let {
    roomId,
    children,
    disableChat,
    customerId,
    isRetail,
    chatWrapperRef
  } = _ref;
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  let viewContent;
  if (isWideDevice) {
    viewContent = React.createElement("div", {
      className: "chat-wrapper-desktop-wrapper marketplace-full-height"
    }, React.createElement("div", {
      className: "chat-wrapper-desktop--details h100 col-sm-6 col-md-8 col-lg-8 col-xl-9",
      ref: chatWrapperRef
    }, children), React.createElement("div", {
      className: "chat-wrapper-desktop--chat".concat(disableChat.isDisabled ? '--disabled' : '', " h100 col-sm-6 col-md-4 col-lg-4 col-xl-3")
    }, disableChat.isDisabled ? React.createElement("div", {
      className: "disabled-chat-message"
    }, React.createElement("p", null, disableChat.reason ? disableChat.reason : 'Chatten är avstängd')) : React.createElement(ErrorBoundary, {
      fallback: defaultErrorFallback
    }, React.createElement(chatUi.ChatDesktopContainer, {
      customerId: customerId,
      headline: "Kommentarer",
      isRetail: isRetail,
      roomId: roomId
    }))));
  } else {
    viewContent = React.createElement("div", {
      className: "chat-detail-scroll js-chat-detail-scroll"
    }, children, !disableChat.isDisabled && React.createElement(React.Fragment, null, React.createElement(chatUi.ChatButtonContainer, {
      isUnreadCountVisible: !isRetail,
      roomId: roomId,
      startY: getY()
    }), React.createElement(ErrorBoundary, {
      fallback: defaultErrorFallback
    }, React.createElement(chatUi.ChatMobileContainer, {
      customerId: customerId,
      headline: "Kommentarer",
      isRetail: isRetail,
      roomId: roomId
    }))));
  }
  return React.createElement(lbUtils.FullHeight, null, viewContent);
};
ChatWrapper.defaultProps = {
  children: null,
  disableChat: {}
};
setGlobal('svs.components.marketplace.chat.ChatWrapper', ChatWrapper);

 })(window);